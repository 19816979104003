<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="fw-bold text-center pb-6" v-if="!showAddDoc">
    <button class="fw-bold fs-6 text-gray-700 pt-4" @click="showAddDoc = true">
      <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi documento
    </button>
  </div>

  <div class="card-body p-6" v-if="showAddDoc">
    <Form
      @submit="onSubmit"
      :validation-schema="schema"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="ps-5 pe-5">
        <div class="row pb-4">
          <div class="col-md-6">
            <label class="fw-bold text-gray-600"> Data documento*</label>
            <button class="bugSolver"></button>
            <Datepicker
              v-model="dataDoc"
              placeholder=""
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              @update:model-value="dataDoc = fromUTCtoISODate($event)"
            />
            <Field
              name="data_riportata"
              type="hidden"
              class="form-control"
              v-model="dataDoc"
            />
            <ErrorMessage name="data_riportata" style="color: red" />
          </div>
          <div class="col-md-6">
            <label class="fw-bold text-gray-600">Tipologia*</label>
            <SelectInput
              :options="
                !affiliazioneSecondaria
                  ? societa_tipo_documenti
                  : societa_tipo_documenti_secondaria
              "
              name="id_tipo"
              @changeSelect="changeTipo($event)"
            />
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-md-12">
            <label class="fw-bold text-gray-600">Oggetto*</label>
            <Field
              name="ogg"
              type="text"
              v-model="oggetto"
              class="form-control"
            />
            <ErrorMessage name="ogg" style="color: red" />
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-md-12">
            <label class="fw-bold text-gray-600">Descrizione*</label>
            <Field
              name="descr"
              as="textarea"
              type="text"
              v-model="descrizione"
              class="form-control"
            />
            <ErrorMessage name="descr" style="color: red" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1">
            <label class="fw-bold text-gray-600">File*</label>
          </div>

          <div class="col-lg-11 d-flex ps-0 pe-xl-11">
            <div
              class="image-input image-input-outline ms-2"
              data-kt-image-input="true"
              style="background-image: url(media/avatars/blank.png)"
            ></div>
          </div>
          <DropZone
            @files-dropped="addFile($event, supportedFormatSocieta)"
            #default="{ dropZoneActive }"
          >
            <label for="file-input-add-soc" style="cursor: pointer">
              <span v-if="dropZoneActive">
                <span>Lascia qui il file </span>
                <span class="smaller">per aggiungerlo</span>
              </span>
              <span v-else>
                <span>Trascina qui il tuo file</span>
                <span class="smaller">
                  oppure
                  <strong><em>clicca qui</em></strong>
                  per selezionare il file
                </span>
              </span>
            </label>
            <input
              type="file"
              id="file-input-add-soc"
              style="display: none"
              :accept="supportedFormatSocieta"
              @change="onInputChange"
            />
            <ul class="image-list" v-if="file.id">
              <FilePreview
                :key="file.id"
                :file="file"
                tag="li"
                @remove="removeFile"
              />
            </ul>
          </DropZone>
          <div class="form-text">
            Tipi di file concessi: {{ supportedFormatSocieta }}
          </div>
        </div>
      </div>
      <div class="text-end">
        <button
          class="badge bg-light-danger rounded text-danger fs-6 me-4"
          @click="
            showAddDoc = false;
            reset();
          "
        >
          Annulla
        </button>
        <button class="badge bg-secondary rounded blueFit fs-6">
          Aggiungi documento
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import TextInput from "../../utility/inputs/TextInput.vue";
import SelectInput from "../../utility/inputs/SelectInput.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import { useStore } from "vuex";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

//componente documenti
import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "documenti-societa-add",
  emits: ["documentAdded"],
  props: ["societa", "affiliazioneSecondaria"],
  components: {
    Loading,
    SelectInput,
    Form,
    Datepicker,
    Field,
    ErrorMessage,
    DropZone,
    FilePreview,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const schema = yup.object().shape({
      id_tipo: yup.string().required("Obbligatorio").nullable(),
      ogg: yup.string().required("Obbligatorio").nullable(),
      descr: yup.string().required("Obbligatorio").nullable(),
      data_riportata: yup.string().required("Obbligatorio").nullable(),
    });

    const dataDoc = ref(fromUTCtoISODate(new Date()));

    // gestione file nuova
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatSocieta.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    let bodyFormData = new FormData();
    const selectedStagione = computed(() => store.getters.stagioneSelected);

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;
      Object.entries(values).map((item) => {
        //
        bodyFormData.append(item[0], item[1]);
      });
      bodyFormData.append("id_societa", props.societa.id);
      bodyFormData.append("anno", selectedStagione.value);
      bodyFormData.append("acl", 0);
      if (file.value.file) {
        bodyFormData.append("file", file.value.file);
        file.value.status = "loading";
      } else {
        alertFailed("Inserire un file!");
        bodyFormData = new FormData();
        disableButton.value = false;
        isLoading.value = false;
        return;
      }

      await sendFiles(
        bodyFormData,
        globalApi.AGGIUNGI_DOCUMENTO_AFFILIAZIONE_ENTE
      ).then((res) => {
        const { status, data } = res;
        if (status != 200) {
          file.value.status = false;
          alertFailed(
            data.message
              ? data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          file.value.status = true;
          alertSuccess(
            "Documento aggiunto con successo",
            "kt_modal_add-doc-societa"
          );
          emit("documentAdded");
          showAddDoc.value = false;
          //reset form
          bodyFormData = new FormData();
          file.value = {};
          dataDoc.value = fromUTCtoISODate(new Date());
          oggetto.value = null;
          descrizione.value = null;
          resetForm();
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const supportedFormatSocieta = ref(
      ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpeg, .jpg"
    );

    const showAddDoc = ref(false);

    const reset = () => {
      bodyFormData = new FormData();
      file.value = {};
      dataDoc.value = fromUTCtoISODate(new Date());
      oggetto.value = null;
      descrizione.value = null;
    };

    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const oggetto = ref(null);
    const descrizione = ref(null);
    const changeTipo = (event) => {
      if (event == 12) {
        oggetto.value = "Modulo affiliazione";
        descrizione.value = "Modulo affiliazione " + stagioneSelected.value;
      } else if (event == 13) {
        oggetto.value = "Piantina campi";
        descrizione.value = "Piantina campi " + stagioneSelected.value;
      } else if (event == 14) {
        oggetto.value = "Responsabile trattamento dati";
        descrizione.value =
          "Responsabile trattamento dati " + stagioneSelected.value;
      }
    };

    return {
      showAddDoc,
      reset,
      societa_tipo_documenti: [
        { id: 15, label: "Modulo aggregazione" },
        { id: 13, label: "Piantina campi" },
        { id: 14, label: "Responsabile trattamento dati" },
      ],
      societa_tipo_documenti_secondaria: [
        { id: 15, label: "Modulo aggregazione" },
        { id: 13, label: "Piantina campi" },
      ],
      changeTipo,
      descrizione,
      oggetto,
      onSubmit,
      schema,
      format,
      fromUTCtoISODate,

      file,
      dataDoc,

      addFile,
      removeFile,
      onInputChange,
      disableButton,
      isLoading,
      supportedFormatSocieta,
    };
  },
};
</script>
