<template>
  <!--begin::details View-->
  <div class="card shadow mb-5 mb-xl-10">
    <loading :active="isLoading" :is-full-page="fullPage"></loading>
    <div class="ps-5 pe-5 pt-6">
      <h6>Membri del consiglio</h6>
    </div>
    <hr />
    <div v-if="!loadedConsiglio">
      <div class="mx-auto my-auto text-center">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
    </div>
    <div
      v-else-if="loadedConsiglio && !elencoConsiglio.length"
      class="mx-auto my-auto text-center pt-5 mb-5 pb-5"
    >
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div v-else>
      <div class="ps-5 pe-5">
        <div class="alert alert-warning" role="alert" v-if="proponiDati">
          <b
            >Attenzione: Sono presentati i dati relativi al consiglio direttivo
            dell'anno {{ stagioneSelected - 1 }} in corso di validità
          </b>
          <br />
          <!-- && isEnabled('fnEnteConsiglioSalvaModifiche') -->
          <span v-if="!readOnly">
            Cliccando su "salva modifiche" le cariche verranno riconfermate per
            la stagione selezionata</span
          >
        </div>
        <div class="row mb-4">
          <div class="col-md-4">
            <label class="fw-bold text-gray-600">Persona</label>
          </div>
          <div class="col-md-3">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Carica</label
            >
          </div>
          <div class="col-md-2">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Data inizio</label
            >
          </div>
          <div class="col-md-2">
            <label class="fw-bold text-gray-600" label-for="disciplina"
              >Data Fine</label
            >
          </div>
        </div>
      </div>
      <div
        class="ps-5 pe-5"
        v-for="(persona, index) in elencoConsiglio"
        :key="persona.id_persona"
      >
        <div class="row mb-4">
          <div class="col-md-4 align-self-center">
            <div class="dp__input_wrap text-gray-600">
              <form autocomplete="off">
                <input
                  name="nominativo_completo"
                  type="text"
                  class="form-control"
                  :value="persona.nominativo_completo"
                  @input="
                    inputFocus = index;
                    persona.nominativo_completo = $event.target.value;
                    getPersone(persona.nominativo_completo);
                  "
                  :disabled="readOnly"
                />
                <!-- || !isEnabled('fnEnteConsiglioSalvaModifiche') -->
                <input
                  type="hidden"
                  class="form-control"
                  placeholder=""
                  aria-label=""
                  v-model="persona.id_persona"
                  autocomplete="off"
                />
              </form>
              <ListPersone
                :list="legali"
                @hideList="
                  resetLegali();
                  inputFocus = null;
                "
                @selectedLegale="
                  persona.nominativo_completo = $event.name;
                  persona.id_persona = $event.value;
                  inputFocus = null;
                  resetLegali();
                "
                :focusOnInput="inputFocus === index"
              ></ListPersone>
              <!-- && isEnabled('fnEnteConsiglioSalvaModifiche') -->
              <svg
                v-if="!readOnly"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                class="dp__icon dp__clear_icon dp__input_icons"
                @click="
                  persona.nominativo_completo = '';
                  persona.id_persona = null;
                "
              >
                <path
                  d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                ></path>
                <path
                  d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="col-md-3 align-self-center">
            <SelectInput
              :options="qualifiche_consiglio"
              :name="`disciplina${index}`"
              :id="`disciplina${index}`"
              :value="elencoConsiglio[index].id_qualifica"
              @changeSelect="persona.id_qualifica = parseInt($event)"
              :disabled="readOnly"
            />
            <!-- || !isEnabled('fnEnteConsiglioSalvaModifiche') -->
          </div>
          <div class="col-md-2 align-self-center">
            <Datepicker
              v-model="persona.dataInizio"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              locale="it"
              autoApply
              @update:model-value="
                persona.dataInizio = fromUTCtoISODate($event)
              "
              :disabled="readOnly"
            />
            <!-- || !isEnabled('fnEnteConsiglioSalvaModifiche') -->
          </div>
          <div class="col-md-2 align-self-center">
            <Datepicker
              v-model="persona.dataFine"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              locale="it"
              autoApply
              @update:model-value="persona.dataFine = fromUTCtoISODate($event)"
              :disabled="readOnly"
            />
            <!-- || !isEnabled('fnEnteConsiglioSalvaModifiche') -->
          </div>
          <div class="col-md-1 fw-bold text-gray-700 text-center">
            <div class="row">
              <div class="col" v-if="!fromAffiliazione">
                <div
                  class="d-flex justify-content-center"
                  v-if="persona.tesserato != 1 && !readOnly"
                >
                  <Popper arrow content="Clicca per tesserare">
                    <i
                      class="bi bi-info-circle-fill text-center text-danger"
                    ></i>
                  </Popper>
                  <button
                    class="badge bg-white rounded ps-1"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_add-tessera-consiglio"
                    @click="
                      id_persona_consiglio = persona.id_persona;
                      nome_persona_consiglio = persona.nominativo_completo;
                      setTesserato();
                    "
                  >
                    <i class="bi bi-credit-card-2-front text-danger fs-4"></i>
                  </button>
                </div>

                <button
                  v-if="persona.tesserato != 1 && readOnly"
                  class="badge bg-white rounded"
                >
                  <i class="bi bi-credit-card-2-front text-danger fs-4"></i>
                </button>
                <span
                  v-if="persona.tesserato == 1"
                  class="badge bg-white rounded"
                >
                  <i class="bi bi-credit-card-2-front text-success fs-4"></i>
                </span>
              </div>
              <div class="col">
                <!-- && isEnabled('fnEnteConsiglioSalvaModifiche') -->
                <button
                  v-if="!readOnly"
                  class="badge bg-light-danger rounded fs-5"
                  @click.prevent="elencoConsiglio.splice(index, 1)"
                >
                  <i class="bi bi-trash text-danger fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddTesseraConsiglio
      :id_ente="id_ente"
      :stagione="stagioneSelected"
      :id_persona_consiglio="id_persona_consiglio"
      :nome_persona_consiglio="nome_persona_consiglio"
      :socio="socio"
      :privacy="privacy"
      :tessereList="tessereList"
      @loadListConsiglio="loadListConsiglio($event.id, $event.year)"
    />
    <hr class="mb-4 mt-4 text-gray-600" />
    <!-- count deve diventare array di oggetti con id e render. se render è false lo cancella -->
    <div v-for="(number, index) in count" :key="index">
      <AddConsiglio
        v-if="number.render"
        :id="index + elencoConsiglio.length"
        :consigliere="number.consigliere"
        @deleteItem="
          count.splice(index, 1);
          changeValue = !changeValue;
        "
        @setPersona="number.consigliere = $event"
        :key="changeValue"
        :index="index"
      />
    </div>
    <!-- && isEnabled('fnEnteConsiglioSalvaModifiche') -->
    <div v-if="!readOnly" class="fw-bold text-center pb-6">
      <button
        class="fw-bold fs-6 text-gray-700"
        @click.prevent="count.push({ render: true })"
      >
        <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi membro
      </button>
    </div>
  </div>

  <ModalAddPersona :fromConsiglio="true" />

  <div class="text-end" v-if="loadedConsiglio">
    <!-- && isEnabled('fnEnteConsiglioSalvaModifiche') -->
    <button
      v-if="!readOnly"
      type="button"
      class="badge bg-secondary rounded blueFit fs-5"
      @click.prevent="editAllConsigli"
    >
      <!-- :disabled="disableUpdate == 1" -->

      Salva Modifiche
    </button>
    <!-- && !isEnabled('fnEnteConsiglioSalvaModifiche') -->
    <button
      v-if="!readOnly && proponiDati"
      type="button"
      class="badge bg-secondary rounded blueFit fs-5"
      @click.prevent="editAllConsigli"
    >
      <!-- :disabled="disableUpdate == 1" -->

      Conferma consiglio
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { useStore } from "vuex";
import ListPersone from "../../../components/components-fit/utility/ListPersone.vue";
import AddConsiglio from "../../../components/components-fit/società/consiglio/AddConsiglio.vue";
import ModalAddPersona from "@/components/components-fit/persone/ModalAddPersona.vue";
import {
  useFormatDateYMD,
  fromUTCtoISODate,
  format,
} from "@/composables/formatDate.js";
import {} from "@/composables/formatDate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import AddTesseraConsiglio from "@/components/components-fit/società/consiglio/AddTesseraConsiglio.vue";
import { tessereSottoscrivibili } from "@/requests/tesseratoUnaSocRequests";
import isEnabled from "@/composables/isEnabled.js";

import {
  editConsiglioEnti,
  addConsiglio,
  listConsiglioEnti,
} from "../../../requests/consiglioDirettivoReq";
import { alertFailed } from "@/composables/swAlert";

import { useRouter } from "vue-router";

import Popper from "vue3-popper";

export default defineComponent({
  name: "consiglio-Ente",
  components: {
    SelectInput,
    Datepicker,
    ListPersone,
    AddConsiglio,
    Loading,
    AddTesseraConsiglio,
    ModalAddPersona,
    Popper,
  },
  // props: ["ente", "campiLocked"],
  props: {
    ente: {
      type: Object,
      required: true,
    },
    fromAffiliazione: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const loadedConsiglio = ref(false);

    const elencoConsiglio = ref([]);

    const qualifiche_consiglio = computed(() =>
      store.getters.getStateFromName("qualifiche_consiglio")
    );
    const keys = ref("qc");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!qualifiche_consiglio.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }
    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const proponiDati = ref(0);
    const loadListConsiglio = (id, year) => {
      loadedConsiglio.value = false;
      elencoConsiglio.value.splice(0, elencoConsiglio.value.length);
      listConsiglioEnti(id, year).then((res) => {
        proponiDati.value = res.data.proponiDati;
        if (res.data.statusCode === 400) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          loadedConsiglio.value = true;
          return;
        }
        res.data.results.forEach((element) => {
          element.dataInizio =
            element.dataInizio != null
              ? useFormatDateYMD(element.dataInizio)
              : null;
          element.dataFine =
            element.dataFine != null
              ? useFormatDateYMD(element.dataFine)
              : null;
          elencoConsiglio.value.push(element);
        });
        loadedConsiglio.value = true;
      });
    };

    if (props.ente) loadListConsiglio(props.ente.id, stagioneSelected.value);
    // cambio lista consiglio al cambio di stagione
    watch(stagioneSelected, (newVal) => {
      if (props.ente) loadListConsiglio(props.ente.id, newVal);
    });

    const addConsiglioRequest = (
      id_persona,
      id_qualifica,
      dataInizio,
      dataFine
    ) => {
      addConsiglio(
        id_persona,
        id_qualifica,
        dataInizio,
        dataFine,
        stagioneSelected.value,
        props.ente.id
      );
    };

    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };
    const inputFocus = ref(null);

    const disableUpdate = ref(0);

    const isLoading = ref(false);
    const fullPage = ref(true);

    const editAllConsigli = async () => {
      /*let loader = useLoading();
      loader.show({
        color: "#0e165e",
      });*/
      isLoading.value = true;

      disableUpdate.value = 1;
      let values = {
        anno: stagioneSelected.value,
        id_Societa: props.ente.id,
      };
      values.consiglio = [];

      elencoConsiglio.value.forEach(async (persona) => {
        let richiestaElement = {};
        richiestaElement.id = persona.id;
        richiestaElement.id_persona = persona.id_persona;
        richiestaElement.id_qualifica = persona.id_qualifica;
        richiestaElement.dataInizio = persona.dataInizio;
        richiestaElement.dataFine = persona.dataFine;
        values.consiglio.push(richiestaElement);
      });

      count.value.forEach((element) => {
        if (
          element.consigliere != null &&
          element.consigliere != "" &&
          element.consigliere != undefined
        ) {
          let richiestaElement = {};
          richiestaElement.id = null;
          richiestaElement.id_persona = element.consigliere.id_persona;
          richiestaElement.id_qualifica = element.consigliere.id_qualifica;
          richiestaElement.dataInizio = element.consigliere.dataInizio;
          richiestaElement.dataFine = element.consigliere.dataFine;
          values.consiglio.push(richiestaElement);
        }
      });

      let responseAdd = editConsiglioEnti(values);
      responseAdd
        .then((value) => {
          //loader.hide();
          disableUpdate.value = 0;
          if (parseInt(value.data.statusCode) == 200) {
            Swal.fire({
              html: "Operazione completata con successo",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            });
            count.value = [];
            loadListConsiglio(props.ente.id, stagioneSelected.value);
          } else {
            const strMsg = ref(value.data.message);
            Swal.fire({
              html: "Si sono verifica i seguenti errori:<br />" + strMsg.value,
              icon: "error",
              confirmButtonText: "Chiudi",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
              width: 600,
              showCancelButton: false,
            });
          }
        })
        .catch(() => {
          //loader.hide();
          disableUpdate.value = 0;
          const strMsg = ref("Si è verificato un errore. Riprovare più tardi");
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          //loader.hide();
          isLoading.value = false;
        });
    };

    //watch changes di stagione per resettare gli oggetti!!

    const router = useRouter();

    const count = ref([]);
    const changeValue = ref(false);
    onMounted(() => {
      if (router.currentRoute._value.name.includes("consiglio-ente"))
        setCurrentPageBreadcrumbs("Consiglio Ente", [
          "Lista Ente",
          "Dettaglio Ente",
        ]);
    });

    const id_ente = ref(props.ente.id);
    const id_persona_consiglio = ref(null);
    const nome_persona_consiglio = ref(null);
    const socio = ref(null);
    const privacy = ref(null);
    const tessereList = ref(null);

    const setTesserato = async () => {
      await tessereSottoscrivibili(
        stagioneSelected.value,
        id_persona_consiglio.value,
        props.ente.id
      ).then((res) => {
        socio.value = res.socio;
        privacy.value = res.privacy;
        if (res.results.length > 0) tessereList.value = res.results;
      });
    };

    return {
      fromUTCtoISODate,
      changeValue,
      inputFocus,
      legali: computed(() => store.getters.personeSocieta),
      getPersone,
      format,
      resetLegali,
      spliceLegali,
      addConsiglioRequest,
      editConsiglioEnti,
      editAllConsigli,
      elencoConsiglio,
      loadedConsiglio,
      qualifiche_consiglio,
      count,
      stagioneSelected,
      proponiDati,
      disableUpdate,
      isLoading,
      fullPage,
      isEnabled,

      id_persona_consiglio,
      nome_persona_consiglio,
      setTesserato,
      socio,
      privacy,
      tessereList,
      id_ente,

      loadListConsiglio,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>

<style scoped>
:deep(.popper) {
  background: #f0416c !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #f0416c !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #f0416c !important;
  left: 0px !important;
}
</style>
