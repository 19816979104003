<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <!-- m-5 pb-2 -->
    <div class="">
      <div class="mt-5 justify-content-between d-flex">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 me-5"
          @click="stampaModuloDomAff()"
        >
          <i class="bi bi-download fs-6 text-gray-700"></i>domanda aggregazione
        </button>
        <a
          href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Aggregazione.docx"
          target="_blank"
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          v-if="!affiliazioneSecondaria"
        >
          <i class="bi bi-download fs-6 text-gray-700"></i> 2024 Designazione
          del Responsabile del trattamento - Aggregazione
        </a>
      </div>

      <br />
      <div class="mx-auto my-auto text-center" v-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="
          documenti_societa_list.length === 0 &&
          loaded &&
          (status == 200 || status == 204)
        "
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2"> Nessun documento presente </span>
      </div>
      <div
        v-else-if="status != 200 && status != 204"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Si è verificato un errore. Riprovare più tardi.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
        >
          <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
            <i class="bi bi-house fs-2 text-gray-800"> </i
          ></router-link>
          <router-link v-else to="/bacheca-public">
            <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
        ></span>
      </div>
      <div v-else>
        <Datatable
          :table-header="tableHeader"
          :table-data="documenti_societa_list"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          :enableItemsPerPageDropdown="record > 10"
          @sort="setSortOrderColumn"
          @items-per-page-change="setFetchRowsDocSoc"
          @current-change="setCurrentPageDocSoc"
        >
          <template v-slot:cell-file_name="{ row: data }">
            <div
              class="text-center"
              @click="downloadDocument(data.item_id, data.estensione_file)"
            >
              <PreviewDaEstensione :estensione="data.estensione_file" />
              <!-- <img
                v-if="data.estensione_file === 'pdf'"
                src="/media/fit-media/PDF.png"
                style="height: 40px"
              />
              <img
                v-if="
                  data.estensione_file === 'doc' ||
                  data.estensione_file === 'txt'
                "
                src="/media/fit-media/DOC.png"
                style="height: 40px"
              /> -->
            </div>
          </template>
          <template v-slot:cell-tipo="{ row: data }">
            {{ data.tipo }}
          </template>
          <template v-slot:cell-ogg="{ row: data }">
            <div class="tab-long">
              {{ data.ogg }}
            </div>
          </template>
          <template v-slot:cell-descr="{ row: data }">
            <div class="tab-long">
              {{ data.descr }}
            </div>
          </template>
          <template v-slot:cell-data="{ row: data }">
            <div class="text-center">
              {{ data.data }}
            </div>
          </template>
          <template v-slot:cell-options="{ row: data }">
            <div class="btn-group" @click="selectedDocId = data.item_id">
              <button
                class="btn btn-sm dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
              </button>
              <ul class="dropdown-menu">
                <a
                  href="#"
                  @click="downloadDocument(data.item_id, data.estensione_file)"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-download text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7" type="button">
                          Download
                        </span>
                      </div>
                    </div>
                  </li></a
                >
                <a href="#" @click="eliminaFile(data.item_id)" v-if="!readOnly">
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-trash text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Elimina </span>
                      </div>
                    </div>
                  </li></a
                >
              </ul>
            </div>
          </template>
        </Datatable>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { deleteDocumentoEnti } from "@/requests/documentiSocieta";
import { downloadElement } from "@/requests/downloadFiles";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { pdfModuloAggregazione } from "@/requests/downloadFiles";

export default defineComponent({
  name: "table-doc-soc",
  emits: ["getDocumentiSocieta", "resetFilters"],
  props: {
    societa: {
      type: Number,
      required: false,
    },
    affiliazioneSecondaria: {
      type: Boolean,
    },
    objControlloDownloadDocumentoAffiliazione: {
      type: Object,
    },
  },
  components: {
    Datatable,
    Loading,
    PreviewDaEstensione,
  },
  setup(props, { emit }) {
    const store = useStore();

    const setFetchRowsDocSoc = (e) => {
      store.commit("setFetchRowsDocSoc", e);
      emit("getDocumentiSocieta");
    };
    const setCurrentPageDocSoc = (page) => {
      store.commit("setCurrentPageDocSoc", page);
      emit("getDocumentiSocieta");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnDocSoc", columnName);
      store.commit("setSortOrderDocSoc", order);
      emit("getDocumentiSocieta");
    };

    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const stampaModuloDomAff = async () => {
      if (
        props.objControlloDownloadDocumentoAffiliazione
          .affiliazionePrecedente == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.atto == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.debiti == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.consiglioDirettivo ==
          "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.discipline == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.affiliazione == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.impiantiCampi == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.privacy == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.sanzioni == "OK" &&
        props.objControlloDownloadDocumentoAffiliazione.tutela_minori == "OK"
      ) {
        isLoading.value = true;
        await pdfModuloAggregazione(props.societa.id, stagioneSelected.value);
        isLoading.value = false;
        return;
      }
      alertFailed(
        "Prima di scaricare la domanda di affiliazione è necessario completare le schede precedenti"
      );
      return;
    };

    const tableHeader = ref([
      {
        key: "file_name",
        sortable: false,
      },
      {
        name: "Tipo",
        key: "tipo",
        sortable: false,
      },
      {
        name: "Oggetto",
        key: "ogg",
      },
      {
        name: "Descrizione",
        key: "descr",
      },
      {
        name: "Data",
        key: "data",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const downloadingFile = ref(false);
    const downloadDocument = async (id, estensione) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(id, "societa", estensione);
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const isLoading = ref(false);

    const eliminaFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumentoEnti(idDoc).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              emit("getDocumentiSocieta");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const selectedDocId = ref("");
    const disabled = ref(false);

    return {
      documenti_societa_list: computed(() =>
        store.getters.getStateFromName("resultsdocumenti_societa_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadeddocumenti_societa_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recorddocumenti_societa_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusdocumenti_societa_list")
      ),
      currentPage: computed(() => store.getters.currentPageDocSoc),
      rowsToSkip: computed(() => store.getters.rowsToSkipDocSoc),
      fetchRows: computed(() => store.getters.fetchRowsDocSoc),
      sortColumn: computed(() => store.getters.sortColumnDocSoc),
      sortOrder: computed(() => store.getters.sortOrderDocSoc),
      setFetchRowsDocSoc,
      setCurrentPageDocSoc,
      setSortOrderColumn,
      tableHeader,
      eliminaFile,
      selectedDocId,
      downloadDocument,
      downloadingFile,
      disabled,
      isLoading,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      stampaModuloDomAff,
    };
  },
});
</script>
<style scoped>
ul.dropdown-menu.show {
  position: fixed !important;
}
</style>
