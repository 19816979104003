<template>
  <div>
    <FilterAssegnaImpianto
      @getImpAssList="searchImpAssList"
      @resetFilters="resetFilters"
      :resetted="resetted"
    />
    <TableAssegnaImpianto
      @getImpAssList="getImpAssList"
      @resetFilters="resetFilters"
      :societa="societa"
      @viewAddImpiantoLista="$emit('viewAddImpiantoLista', $event)"
      @viewAssegnazioneDetail="$emit('viewAssegnazioneDetail', $event)"
      @AssCampi="$emit('AssCampi', $event)"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import TableAssegnaImpianto from "@/components/components-fit/società/affiliazioni/TableAssegnaAffiliazione.vue";
import FilterAssegnaImpianto from "@/components/components-fit/società/affiliazioni/FilterAssegnaAffiliazione.vue";

export default defineComponent({
  name: "assegna-impianto-societa",
  components: {
    TableAssegnaImpianto,
    FilterAssegnaImpianto,
  },
  emits: ["viewAddImpiantoLista", "viewAssegnazioneDetail", "AssCampi"],
  props: {
    societa: {
      type: Object,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Assegnazione Impianti", [
        "Lista Societa",
        "Dettaglio Societa",
        "Impianti Società",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    /*  const stagione = computed(() => store.getters.stagioneSelected); */
    const rowsToSkip = computed(() => store.getters.rowsToSkipImpAss);
    const fetchRows = computed(() => store.getters.fetchRowsImpAss);
    const sortColumn = computed(() => store.getters.sortColumnImpAss);
    const sortOrder = computed(() => store.getters.sortOrderImpAss);

    const denominazione = computed(() => store.getters.denominazioneImpAss);
    const id_provincia = computed(() => store.getters.provinciaImpAss);
    const id_comune = computed(() => store.getters.comuneImpAss);

    const getImpAssList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_provincia: id_provincia.value,
          id_comune: id_comune.value,
          denominazione: trimInput(denominazione.value),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.AFFILIAZIONI_ENTI_AGGREGATI_IMPIANTI_SELEZIONE_LIST,
        itemName: "impianti_assegna_list",
      });
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedimpianti_assegna_list")
    );
    const searchImpAssList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipImpAss");
      getImpAssList();
    };

    const resetted = ref(false);

    const resetFilters = () => {
      store.commit("resetFiltersImpAss");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getImpAssList();
    };
    getImpAssList();

    return {
      searchImpAssList,
      getImpAssList,
      resetFilters,
      resetted,
    };
  },
});
</script>
