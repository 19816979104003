import services from "../axios/dbManag";

export const viewAtto = (id) => {
  return services.dbManag
    .post(`/anagrafiche/societa/atti/view`, {
      id,
    })
    .then((res) => {
      const status = res.status;
      const { campi_Locked, comandi_Locked, record, results } = res.data;
      return { campi_Locked, comandi_Locked, record, results, status };
    })
    .catch((e) => {
      // erroreStatus.value = e.response.status;
      const { status } = e.response;
      return { status };
    });
};

export const editAtto = (atto) => {
  return services.dbManag
    .post(`/anagrafiche/societa/atti/edit`, {
      ...atto,
    })
    .then(() => true)
    .catch(() => false);
};

export const viewAttoEnte = (id) => {
  return services.dbManag
    .post(`/anagrafiche/enti-aggregati/atti/view`, {
      id,
    })
    .then((res) => {
      const status = res.status;
      const { campi_Locked, comandi_Locked, record, results } = res.data;
      return { campi_Locked, comandi_Locked, record, results, status };
    })
    .catch((e) => {
      // erroreStatus.value = e.response.status;
      const { status } = e.response;
      return { status };
    });
};

export const editAttoEnte = (atto) => {
  return services.dbManag
    .post(`/anagrafiche/enti-aggregati/atti/edit`, {
      ...atto,
    })
    .then(() => true)
    .catch(() => false);
};
