<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        Nuova affiliazione secondaria {{ societa ? societa.denominazione : "" }}
      </h5>
      <!--begin::Close-->
      <div
        class="btn btn-icon btn-sm btn-active-secondary"
        style="border: 0; !important"
        data-bs-dismiss="modal"
        aria-label="Close"
        @click="
          setComitato(null);
          setEmail('');
        "
      >
        <i class="bi bi-x-lg"> </i>
      </div>
      <!--end::Close-->
    </div>
    <div class="modal-body">
      <!--<div class="bg-secondary py-5 px-4 rounded fw-bold">
       <div class="blueFit fs-6">
          In quest'area è possibile richiedere un'affiliazione secondaria per la
          società {{ societa.denominazione }}
        </div> 
      </div>   <div class="pt-4">-->
      <div class="">
        <div class="row">
          <div class="col-4">
            Selezionare il comitato regionale: <br />
            <SelectInput
              placeholder="Comitato"
              :options="comitati"
              :value="selectedComitato"
              name="id_periferico"
              @changeSelect="setComitato($event)"
            />
          </div>
          <div class="col-4">
            Inserire l'email di contatto: <br />
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              :value="email"
              @input="setEmail($event.target.value)"
            />
          </div>
          <div class="col" v-if="selectedComitato && email">
            <br />
            <button
              type="button"
              class="badge bg-light-success text-success rounded fs-6"
              @click="checkEmail()"
            >
              Continua
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="me-3 badge bg-light-danger rounded text-danger fs-5"
        data-bs-dismiss="modal"
        aria-label="Close"
        id="closeModalSelezioneComitato"
        @click="
          setComitato(null);
          setEmail();
        "
      >
        Chiudi
      </button>
    </div>
  </div>
</template>

<script>
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { alertFailed } from "@/composables/swAlert";
export default {
  components: {
    SelectInput,
  },
  props: {
    societa: {
      type: Object,
    },
  },
  emits: ["checkComitatoAffSecondaria"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const email = computed(() => store.getters.emailAffSecondaria);
    const selectedComitato = computed(
      () => store.getters.selectedComitatoAffSecondaria
    );
    const setComitato = (value) => {
      store.commit("setSelectedComitatoAffSecondaria", value);
    };
    const setEmail = (value) => {
      store.commit("setEmailAffSecondaria", value);
    };

    const regexEmail = ref(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const checkEmail = () => {
      if (regexEmail.value.test(email.value)) {
        emit("checkComitatoAffSecondaria");
      } else {
        alertFailed("Inserire una mail valida");
      }
    };

    return {
      comitati,
      setComitato,
      setEmail,
      selectedComitato,
      email,
      checkEmail,
    };
  },
};
</script>

<style></style>
