<template>
  <!--begin::details View-->
  <form autocomplete="off">
    <div class="ps-5 pe-5">
      <div class="row mb-4">
        <div class="col-md-4">
          <label class="fw-bold text-gray-600">Persona</label>

          <div class="dp__input_wrap text-gray-600">
            <input
              type="text"
              class="form-control"
              placeholder=""
              aria-label=""
              v-model="consigliereSocieta.nominativo_completo"
              @input="
                getPersone($event.target.value);
                focus = true;
              "
              autocomplete="off"
            />
            <input
              type="hidden"
              class="form-control"
              v-model="consigliereSocieta.id_persona"
            />
            <ListPersone
              :list="legali"
              @hideList="
                resetLegali();
                focus = false;
              "
              @selectedLegale="
                consigliereSocieta.nominativo_completo = $event.name;
                consigliereSocieta.id_persona = $event.value;
                $emit('setPersona', {
                  ...consigliereSocieta,
                  nominativo_completo: $event.name,
                  id_persona: $event.value,
                });
                focus = false;
                resetLegali();
              "
              :focusOnInput="focus"
            ></ListPersone>

            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              class="dp__icon dp__clear_icon dp__input_icons"
              @click="
                consigliereSocieta.nominativo_completo = '';
                consigliereSocieta.id_persona = null;
              "
            >
              <path
                d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
              ></path>
              <path
                d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="col-md-3">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Carica</label
          >
          <SelectInput
            :name="`carica${index}`"
            :id="`carica${index}`"
            :options="qualifiche_consiglio"
            :value="consigliereSocieta.id_qualifica"
            @changeSelect="
              consigliereSocieta.id_qualifica = parseInt($event);
              $emit('setPersona', {
                ...consigliereSocieta,
                id_qualifica: parseInt($event),
              });
            "
          />
        </div>
        <div class="col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Data inizio</label
          >
          <Datepicker
            v-model="consigliereSocieta.dataInizio"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            locale="it"
            @update:modelValue="
              if ($event) {
                consigliereSocieta.dataInizio = fromUTCtoISODate($event);
                $emit('setPersona', {
                  ...consigliereSocieta,
                  dataInizio: fromUTCtoISODate($event),
                });
              }
            "
            autoApply
          />
        </div>
        <div class="col-md-2">
          <label class="fw-bold text-gray-600" label-for="disciplina"
            >Data fine</label
          >
          <Datepicker
            v-model="consigliereSocieta.dataFine"
            :format="format"
            :monthChangeOnScroll="false"
            :enableTimePicker="false"
            inputClassName="h-28"
            locale="it"
            @update:modelValue="
              if ($event) {
                consigliereSocieta.dataFine = fromUTCtoISODate($event);
                $emit('setPersona', {
                  ...consigliereSocieta,
                  dataFine: fromUTCtoISODate($event),
                });
              }
            "
            autoApply
          />
        </div>
        <div
          class="col-md-1 fw-bold text-gray-700 text-center mt-5 text-danger"
        >
          <button
            class="badge bg-light-danger rounded fs-5"
            @click.prevent="$emit('deleteItem')"
          >
            <i class="bi bi-trash text-danger"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
} from "vue";
import ListPersone from "../../utility/ListPersone.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { useStore } from "vuex";
import { fromUTCtoISODate, format } from "@/composables/formatDate.js";
import "vue3-date-time-picker/dist/main.css";
export default defineComponent({
  name: "addConsiglio",
  components: {
    ListPersone,
    SelectInput,
    Datepicker,
  },
  emits: ["deleteItem", "setPersona"],
  props: {
    id: {
      type: Number,
      required: true,
    },
    consigliere: {
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  // eslint-disable-next-line no-empty-pattern
  setup(props, {}) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const qualifiche_consiglio = computed(() =>
      store.getters.getStateFromName("qualifiche_consiglio")
    );
    const keys = ref("qc");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!qualifiche_consiglio.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const consigliereSocieta = ref({});

    onMounted(() => {
      consigliereSocieta.value = props.consigliere
        ? props.consigliere
        : {
            nominativo_completo: "",
            id_persona: "",
            id_qualifica: "",
            dataInizio: "",
            dataFine: "",
          };
    });

    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const focus = ref(false);

    return {
      focus,
      getPersone,
      resetLegali,
      spliceLegali,
      legali: computed(() => store.getters.personeSocieta),
      qualifiche_consiglio,
      consigliereSocieta,
      fromUTCtoISODate,
      format,
    };
  },
});
</script>
