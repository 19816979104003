<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="modal_edit-detail-affiliazione_ente"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Modifica aggregazione
              {{
                affiliazioneSecondaria
                  ? " secondaria " + ente.denominazione
                  : ""
              }}
            </h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0 !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <template v-if="statusAffiliazione === 0">
            <div class="mx-auto my-auto text-center py-5 my-5">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
          </template>

          <template v-if="affiliazione.length == 0 && statusAffiliazione != 0">
            <span class="text-gray-700 ps-3 fs-2">
              Attenzione! Nessun risultato.
            </span>
          </template>
          <template v-else>
            <div class="modal-body" v-if="affiliazione.id_affiliazione">
              <div class="card-body p-6">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="col-md-3">
                      <label class="fw-bold">Data aggregazione</label>
                    </div>
                    <div class="col-md-3">
                      <Datepicker
                        v-model="affiliazione.data_inizio"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        disabled
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row pb-4">
                    <p class="">
                      Consenso al trattamento dati per TESSERAMENTO
                    </p>
                    <div class="col-md-6 d-flex">
                      <label class="fw-bold">personali</label>
                      <div class="ms-4 mt-1">
                        <Field
                          class="form-check-input"
                          type="checkbox"
                          name="privacy_Dati_Pers_Reg2"
                          :value="true"
                          v-model="affiliazione.privacy_dati_pers_reg2"
                        />
                        <ErrorMessage
                          name="privacy_Dati_Pers_Reg2"
                          style="color: red"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 d-flex">
                      <label class="fw-bold">per fini commerciali</label>
                      <div class="ms-4 mt-1">
                        <Field
                          class="form-check-input"
                          type="checkbox"
                          name="privacy_Info_Commerc_Reg2"
                          :value="true"
                          v-model="affiliazione.privacy_info_commerc_reg2"
                        />
                        <ErrorMessage
                          name="privacy_Info_Commerc_Reg2"
                          style="color: red"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <p class="">Discipline dell'aggregazione</p>
                    <div class="col-md-4 d-flex">
                      <label class="fw-bold">Tennis</label>
                      <div class="ms-4 mt-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          :checked="
                            affiliazione.discipline.includes('4332')
                              ? true
                              : false
                          "
                          @click="setStringDiscipline($event, '4332')"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <label class="fw-bold">Padel</label>
                      <div class="ms-4 mt-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          :checked="
                            affiliazione.discipline.includes('172')
                              ? true
                              : false
                          "
                          @click="setStringDiscipline($event, '172')"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 d-flex">
                      <label class="fw-bold">Beach Tennis</label>
                      <div class="ms-4 mt-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          :checked="
                            affiliazione.discipline.includes('171')
                              ? true
                              : false
                          "
                          @click="setStringDiscipline($event, '171')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6 d-flex">
                      <label class="fw-bold">Wheelchair Tennis</label>
                      <div class="ms-4 mt-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          :checked="
                            affiliazione.discipline.includes('173')
                              ? true
                              : false
                          "
                          @click="setStringDiscipline($event, '173')"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 d-flex">
                      <label class="fw-bold">Pickleball</label>
                      <div class="ms-4 mt-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          :checked="
                            affiliazione.discipline.includes('174')
                              ? true
                              : false
                          "
                          id="flexCheckDefault"
                          @click="setStringDiscipline($event, '174')"
                        />
                      </div>
                    </div>
                  </div>
                  <Field
                    name="discipline"
                    type="hidden"
                    v-model="affiliazione.discipline"
                  />
                  <ErrorMessage name="discipline" style="color: red" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <span
                type="button"
                class="me-3 badge bg-light-danger rounded text-danger fs-6"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalEditAff"
              >
                Chiudi
              </span>
              <button
                type="submit"
                class="badge bg-secondary rounded blueFit fs-6"
                :disabled="disableButton"
              >
                Salva Modifiche
              </button>
            </div>
          </template>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref, computed } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import {
  viewAffiliazione,
  editAffiliazione,
} from "@/requests/checkAffiliazione";
import { format } from "@/composables/formatDate.js";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import { useStore } from "vuex";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "edit-detail-affiliazione",
  emits: ["loadAffiliazioniEnte"],
  props: {
    idAffiliazione: {
      type: Number,
      required: true,
    },
    ente: {
      type: Object,
      required: true,
    },
    affiliazioneSecondaria: {
      type: Boolean,
    },
  },
  components: { Loading, Datepicker, Form, Field, ErrorMessage },
  setup(props, { emit }) {
    const store = useStore();
    const stagione = computed(() => store.getters.stagioneSelected);
    const affiliazione = ref({});
    const statusAffiliazione = ref(0);
    const getAffiliazione = (id) => {
      viewAffiliazione(id).then((res) => {
        if (res.status == 200) {
          res.affiliazione.discipline = res.affiliazione.discipline
            ? res.affiliazione.discipline + ";"
            : "";
          statusAffiliazione.value = res.status;
          affiliazione.value = res.affiliazione;
        } else {
          statusAffiliazione.value = res.status;
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "modal_edit-detail-affiliazione"
          );
        }
      });
    };
    watch(
      () => props.idAffiliazione,
      (value) => {
        getAffiliazione(value);
      }
    );
    const setStringDiscipline = (e, idDisciplina) => {
      if (e.target.checked) {
        // aggiungi id
        affiliazione.value.discipline =
          affiliazione.value.discipline + idDisciplina + ";";
      } else {
        //togli id disciplina
        affiliazione.value.discipline = affiliazione.value.discipline.replace(
          idDisciplina + ";",
          ""
        );
      }
    };

    const alertCampiObb = ref(false);

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const disableButton = ref(false);
    const isLoading = ref(false);

    const schema = yup.object().shape({
      privacy_Info_Commerc_Reg: yup.boolean().nullable(),
      privacy_Dati_Pers_Reg2: yup
        .boolean()
        .oneOf([true], "Obbligatorio")
        .required("Obbligatorio"),
      privacy_Info_Commerc_Reg2: yup.boolean().nullable(),
      discipline: yup
        .string()
        .nullable()
        .required("Selezionare almeno una disciplina"),
    });

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      if (!values.discipline.length) {
        alertFailed("Selezionare almeno una disciplina");
        return;
      }
      disableButton.value = true;
      isLoading.value = true;
      values.discipline = values.discipline.slice(0, -1);
      values = {
        ...values,
        id: affiliazione.value.id_affiliazione,
        id_Societa: props.ente.id,
        anno: stagione.value,
      };

      editAffiliazione(values).then((res) => {
        if (res.status == 200) {
          alertSuccess("Aggregazione modificata con successo");
          document.getElementById("closeModalEditAff").click();
          emit("loadAffiliazioniEnte");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };
    return {
      affiliazione,
      statusAffiliazione,
      onSubmit,
      onInvalidSubmit,
      setStringDiscipline,
      format,
      disableButton,
      isLoading,
      alertCampiObb,
      schema,
    };
  },
});
</script>

<style scoped>
@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
</style>
