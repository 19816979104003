import services from "@/axios/dbManag";

export const viewDocumento = (item_Id) => {
  return services.dbManag
    .post("/affiliazioni/societa/documenti/view", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const deleteDocumento = (item_Id) => {
  return services.dbManag
    .post("/affiliazioni/societa/documenti/del", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const viewDocumentoEnti = (item_Id) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/documenti/view", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const deleteDocumentoEnti = (item_Id) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/documenti/del", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const codiceCondottaCreate = (id_societa, id_tipo, data_rilascio) => {
  return services.dbManag
    .post("/affiliazioni/societa/modulo/codice-condotta/create", {
      id_societa,
      id_tipo,
      data_rilascio,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};

export const modelloOrganizzazioneCreate = (
  id_societa,
  id_tipo,
  data_rilascio
) => {
  return services.dbManag
    .post("/affiliazioni/societa/modulo/modello-organizzazione/create", {
      id_societa,
      id_tipo,
      data_rilascio,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};
