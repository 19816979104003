<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <Form
    v-slot="{ validate, values }"
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="accordion" id="accordionAddPersona">
      <form autocomplete="off">
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingOne">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseOne"
              >
                Dati Anagrafici
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Cognome*</label>
                        <TextInput name="cognome" type="text" />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600">Nome*</label>
                        <TextInput name="nome" type="text" />
                      </div>

                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Data di nascita*</label
                        >
                        <Datepicker
                          v-model="data_nascita"
                          name="data_nascita"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                          :maxDate="new Date()"
                        />
                        <Field
                          name="data_nascita"
                          type="hidden"
                          class="form-control"
                          v-model="data_nascita"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_nascita"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Codice Fiscale*</label
                        >
                        <TextInput
                          name="cod_fisc"
                          type="text"
                          :value="cod_fisc"
                        />
                      </div>
                      <div class="col-md-2 mb-4">
                        <label class="fw-bold text-gray-600">Sesso*</label>
                        <SelectInput :options="sesso" name="sesso" />
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Cittadinanza*</label
                        >
                        <SelectInput
                          :options="statiCittadinanza"
                          name="cittadinanza"
                          :value="null"
                          @changeSelect="
                            id_tipo_docum_straniero = null;
                            permesso_Soggiorno = false;
                          "
                        />
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Cittadinanza Sportiva</label
                        >
                        <SelectInput
                          :options="statiCittadinanza"
                          name="cittadinanza_sportiva"
                          :value="null"
                          :disabled="
                            !isEnabled('fnPersoneAddCittadinanzaSportiva')
                          "
                          @changeSelect="
                            id_tipo_docum_straniero = null;
                            permesso_Soggiorno = false;
                          "
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Diversamente abile</label
                        >
                        <div class="mt-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="flexcheckboxDefault"
                            v-model="disabile"
                          />
                          <Field
                            type="hidden"
                            name="disabile"
                            v-model="disabile"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600">Transgender</label>
                        <div class="mt-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="flexcheckboxDefault"
                            v-model="transgender"
                            @click="values.transgender_tipo = null"
                          />
                          <Field
                            type="hidden"
                            name="transgender"
                            v-model="transgender"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600">Tipologia</label>
                        <SelectInput
                          :options="transgender_tipi"
                          name="transgender_tipo"
                          :disabled="!transgender"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">E-mail*</label>
                        <TextInput name="email" type="email" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Skype</label>
                        <TextInput name="skype" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingTwo">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseTwo"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseTwo"
              >
                Luogo di nascita
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingTwo"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-2 mb-4">
                        <label class="fw-bold text-gray-600" for="italia"
                          >Italia</label
                        >
                        <Field
                          v-model="straniero"
                          class="form-check-input mt-8"
                          type="radio"
                          name="straniero"
                          id="italia"
                          :value="0"
                          :checked="!straniero"
                          @click="
                            comune_nascita = 0;
                            straniero = 0;
                            comune_nascita_label = '';
                            stato_nascita = null;
                            localita_nascita = '';
                          "
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Comune nascita*</label
                        >
                        <Field
                          name="comune_nascita_label"
                          type="text"
                          placeholder="Digita almeno 3 caratteri..."
                          class="form-control"
                          v-model="comune_nascita_label"
                          @input="
                            spliceComuniNascita();
                            setComuniNascita($event.target.value);
                          "
                          @focus="inputFocus = 'comuneN'"
                          @keyup.delete="
                            spliceComuniNascita();
                            comune_nascita = 0;
                          "
                          :disabled="straniero"
                          autocomplete="off"
                        />
                        <Field
                          :disabled="straniero"
                          name="comune_nascita"
                          type="hidden"
                          class="form-control"
                          v-model="comune_nascita"
                        />
                        <ListComuni
                          v-if="comuniNascita && inputFocus === 'comuneN'"
                          :list="comuniNascita"
                          @hideList="spliceComuniNascita"
                          @selectedElement="
                            comune_nascita_label = $event.name;
                            comune_nascita = $event.value;
                            spliceComuniNascita();
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_nascita"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2 mb-4">
                        <label class="fw-bold text-gray-600" for="estero"
                          >Estero</label
                        >
                        <Field
                          v-model="straniero"
                          class="form-check-input mt-8"
                          type="radio"
                          name="straniero"
                          id="estero"
                          :value="1"
                          :checked="straniero"
                          @click="
                            stato_nascita = null;
                            comune_nascita = 0;
                            comune_nascita_label = '';
                            localita_nascita = '';
                          "
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Nazione nascita*</label
                        >
                        <SelectInput
                          :options="statiNascita"
                          name="stato_nascita"
                          :disabled="!straniero"
                          :reset="!straniero"
                          :value="0"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600">Località</label>
                        <Field
                          name="localita_nascita"
                          type="text"
                          placeholder=""
                          class="form-control"
                          v-model="localita_nascita"
                          :disabled="!straniero"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="localita_nascita"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingThree">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseThree"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseThree"
              >
                Residenza
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseThree"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingThree"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Nazione residenza*</label
                        >
                        <Field
                          name="nazione_residenza"
                          as="select"
                          class="form-select"
                          v-model="nazione_residenza"
                          @change="
                            changeselectedItaliaR($event.target.value);
                            validate();
                            id_provincia_residenza = 0;
                            comune_residenza_txt = '';
                            id_comune_residenza = 0;
                          "
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="nazione in statiResidenza"
                            :value="nazione.id"
                            :key="nazione"
                          >
                            {{ nazione.label }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="nazione_residenza"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Provincia residenza*</label
                        >
                        <Field
                          name="prov_Residenza"
                          as="select"
                          class="form-select"
                          v-model="id_provincia_residenza"
                          @change="
                            changeselectedProvinciaR($event.target.value);
                            validate();
                            comune_residenza_txt = '';
                            id_comune_residenza = 0;
                          "
                          :disabled="selectedItaliaR ? false : true"
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="provincia in province"
                            :value="provincia.codP"
                            :key="provincia.codP"
                          >
                            {{ provincia.nomeP }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="prov_Residenza"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Comune residenza*</label
                        >
                        <Field
                          name="comune_residenza_txt"
                          type="text"
                          class="form-control"
                          @input="
                            spliceComuniIstat();
                            setComuni(
                              comune_residenza_txt,
                              id_provincia_residenza
                            );
                          "
                          @focus="inputFocus = 'comuneR'"
                          @keyup.delete="
                            spliceComuniIstat();
                            id_comune_residenza = null;
                          "
                          v-model="comune_residenza_txt"
                          :disabled="
                            selectedProvinciaR && selectedItaliaR ? false : true
                          "
                          autocomplete="off"
                        />
                        <Field
                          name="comune_residenza"
                          type="hidden"
                          v-model="id_comune_residenza"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="comuniIstat && inputFocus === 'comuneR'"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            spliceComuniIstat();
                            id_comune_residenza = $event.value;
                            comune_residenza_txt = $event.name;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_residenza"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Indirizzo*</label>
                        <TextInput name="indirizzo_residenza" type="text" />
                      </div>
                      <div class="col-md-2">
                        <label class="fw-bold text-gray-600">C.A.P.*</label>
                        <TextInput name="cap_residenza" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Località</label>
                        <TextInput name="localita_residenza" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Telefono 1</label>
                        <TextInput name="telefono" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Telefono 2</label>
                        <TextInput name="telefono2" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Fax</label>
                        <TextInput name="fax" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Cellulare</label>
                        <TextInput name="mobile" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingFour">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseFour"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseFour"
              >
                Documento d'identità
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseFour"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingFour"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600">Tipo</label>
                        <SelectInput
                          :options="tipiDocIdentita"
                          name="tipo_doc"
                        />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Numero</label>
                        <TextInput name="numero_doc" type="text" />
                      </div>
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Data scadenza</label
                        >
                        <Datepicker
                          v-model="data_doc"
                          name="data_doc"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :minDate="new Date()"
                        />
                        <Field
                          name="data_doc"
                          type="hidden"
                          class="form-control"
                          v-model="data_doc"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_doc"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Rilasciato da</label
                        >
                        <SelectInput
                          :options="tipiDocRilascio"
                          name="rilascio_doc"
                          @changeSelect="changeSelectedDoc($event)"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Provincia rilascio</label
                        >
                        <Field
                          name="prov_Doc"
                          as="select"
                          class="form-select"
                          v-model="id_provincia_rilascio"
                          @change="
                            changeselectedProvinciaRD($event.target.value);
                            comune_rilascio_txt = '';
                            id_comune_rilascio = 0;
                          "
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="provincia in province"
                            :value="provincia.codP"
                            :key="provincia.codP"
                          >
                            {{ provincia.nomeP }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="prov_Doc"
                        />
                      </div>
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Comune rilascio</label
                        >
                        <Field
                          name="comune_rilascio"
                          type="text"
                          class="form-control"
                          @input="
                            spliceComuniIstat();
                            setComuni(
                              comune_rilascio_txt,
                              id_provincia_rilascio
                            );
                          "
                          @focus="inputFocus = 'comuneRD'"
                          @keyup.delete="spliceComuniIstat()"
                          v-model="comune_rilascio_txt"
                          :disabled="!id_provincia_rilascio"
                          autocomplete="off"
                        />
                        <Field
                          name="comune_Doc"
                          type="hidden"
                          v-model="id_comune_rilascio"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="comuniIstat && inputFocus === 'comuneRD'"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            spliceComuniIstat();
                            id_comune_rilascio = $event.value;
                            comune_rilascio_txt = $event.name;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="comune_Doc"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="fw-bold text-gray-600">Note</label>
                        <TextInput name="note_doc" type="text" />
                      </div>
                    </div>
                    <div class="row" v-if="values.cittadinanza != 0">
                      <div class="col-md-4 d-flex flex-column">
                        <label class="fw-bold text-gray-600"
                          >Motivazione Permesso
                        </label>
                        <div class="mt-1">
                          <SelectInputEvento
                            :options="straniero_tipo_documenti"
                            :value="id_tipo_docum_straniero"
                            :cittadinanza_sportiva_persona="
                              values.cittadinanza_sportiva
                            "
                            name="permesso_soggiorno"
                            @changeSelect="cambiaPermessoDiSoggiorno($event)"
                          />
                          <Field
                            name="permesso_Soggiorno"
                            type="hidden"
                            v-model="permesso_Soggiorno"
                            class="form-control"
                          />
                          <!--<TextInput
                            :value="permesso_Soggiorno"
                            name="permesso_Soggiorno"
                            type="hidden"
                            :checked="permesso_Soggiorno"
                            @changeEvent="
                              permesso_Soggiorno = !permesso_Soggiorno;
                              changeDataPermesso();
                            "
                          />-->
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Data Scadenza</label
                        >
                        <Datepicker
                          v-model="data_Scadenza_Permesso_Soggiorno"
                          name="data_Scadenza_Permesso_Soggiorno"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :minDate="new Date()"
                          :disabled="
                            !permesso_Soggiorno ||
                            !view_scadenza_permesso_soggiorno
                          "
                        />
                        <Field
                          name="data_Scadenza_Permesso_Soggiorno"
                          type="hidden"
                          class="form-control"
                          v-model="data_Scadenza_Permesso_Soggiorno"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Scadenza_Permesso_Soggiorno"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingFour">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseFive"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseFive"
              >
                Coordinate bancarie
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseFive"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingFive"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Banca 1</label>

                        <TextInput name="banca" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Agenzia 1</label>

                        <TextInput name="indirizzo_banca" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">IBAN 2</label>
                        <TextInput name="iban" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Banca 2</label>
                        <TextInput name="banca_2" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Agenzia 2</label>
                        <TextInput name="indirizzo_banca_2" type="text" />
                      </div>
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">IBAN 2</label>
                        <TextInput name="iban_2" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingFour">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseSix"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseSix"
              >
                Altre informazioni
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseSix"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingSix"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600">Taglia</label>
                        <SelectInput :options="taglie" name="taglia" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Peso (Kg)</label>
                        <TextInput name="peso" type="text" />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Altezza (cm)</label
                        >
                        <TextInput name="altezza" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="col-md-4 mb-4">
                    <label class="fw-bold text-gray-600">Cognome materno</label>

                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      aria-label=""
                    />
                  </div> -->
                      <div class="col-md-4 mb-4">
                        <label class="fw-bold text-gray-600"
                          >Titolo di studio</label
                        >
                        <SelectInput
                          :options="titoliStudio"
                          name="id_Titolo_Studio"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 mb-4">
                        <label class="fw-bold text-gray-600 p-1"
                          >Lingue conosciute</label
                        >
                        <SelectInput
                          :options="lingueParlate"
                          name="id_lingue1"
                          :value="id_lingue1"
                          @changeSelect="changeLingua1($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          name="id_lingue2"
                          :value="id_lingue2"
                          :disabled="lingue2disabled ? true : false"
                          @changeSelect="changeLingua2($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          name="id_lingue3"
                          :value="id_lingue3"
                          :disabled="lingue3disabled ? true : false"
                          @changeSelect="changeLingua3($event)"
                        />
                      </div>
                      <div class="col-md-3 mb-4 no-label">
                        <SelectInput
                          :options="lingueParlate"
                          name="id_lingue4"
                          :value="id_lingue4"
                          :disabled="lingue4disabled ? true : false"
                          @changeSelect="changeLingua4($event)"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="fw-bold text-gray-600">Targhe auto</label>
                        <TextInput name="targa1" type="text" />
                      </div>
                      <div class="col-md-4 mb-4 no-label-add">
                        <TextInput name="targa2" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6 class="accordion-header" id="accordionAddPersona-headingSeven">
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAddPersona-collapseSeven"
                aria-expanded="true"
                aria-controls="accordionAddPersona-collapseSeven"
              >
                Trattamento dati personali
              </button>
            </h6>
            <div
              id="accordionAddPersona-collapseSeven"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAddPersona-headingSeven"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-4 d-flex">
                        <label class="fw-bold text-gray-600"
                          >Paragrafo 1.A</label
                        >
                        <div class="ms-4 mt-1">
                          <TextInput name="privacy1" type="checkbox" />
                        </div>
                      </div>
                      <div class="col-md-4 d-flex">
                        <label class="fw-bold text-gray-600"
                          >Paragrafo 1.B</label
                        >
                        <div class="ms-4 mt-1">
                          <TextInput
                            name="privacy2"
                            type="checkbox"
                            :value="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 d-flex">
                        <label class="fw-bold text-gray-600"
                          >Paragrafo 1.C</label
                        >
                        <div class="ms-4 mt-1">
                          <TextInput
                            name="privacy3"
                            type="checkbox"
                            :value="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 d-flex">
                        <label class="fw-bold text-gray-600"
                          >Paragrafo 1.D</label
                        >
                        <div class="ms-4 mt-1">
                          <TextInput
                            name="privacy4"
                            type="checkbox"
                            :value="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 d-flex">
                        <label class="fw-bold text-gray-600"
                          >Paragrafo 1.E</label
                        >
                        <div class="ms-4 mt-1">
                          <TextInput
                            name="privacy5"
                            type="checkbox"
                            :value="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--end::details View-->
    <div class="text-end">
      <button
        type="buttom"
        @click="$emit('nascondi_add_anagrafica')"
        class="badge bg-secondary rounded blueFit fs-5"
      >
        Nascondi aggiunta anagrafica
      </button>
      <button
        type="submit"
        class="ms-2 badge bg-secondary rounded blueFit fs-5"
      >
        Salva Anagrafica
      </button>
    </div>
  </Form>
</template>

<script>
const debugPrint = false;
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  computed,
  ref,
} from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

// servizi
import { useRouter } from "vue-router";

// componenti standard
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

// componenti
import TextInput from "../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../components/components-fit/utility/inputs/SelectInput_v01.vue";
import ListComuni from "../../components/components-fit/utility/ListComuni.vue";

// gestione stato
import { useStore } from "vuex";

// richieste dati
import { addPersona } from "../../requests/personaRequests";

// validazione e alert
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

//check
import { checkCodFisc } from "../../requests/checkCodiceFiscale";

// css
import "vue3-date-time-picker/dist/main.css";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { alertSuccess } from "@/composables/swAlert";
import { fromUTCtoISODateDMY } from "@/composables/formatDate";

import isEnabled from "@/composables/isEnabled.js";
import SelectInputEvento from "../../components/components-fit/utility/inputs/SelectInputEvento.vue";

// componente
export default defineComponent({
  name: "add-persona",
  emits: ["setupTesseramento", "nascondi_add_anagrafica"],
  props: {
    fromSocieta: {
      type: Boolean,
      default: false,
    },
    fromAddSocieta: {
      type: Boolean,
    },
    fromTesseramento: {
      type: Boolean,
      default: false,
    },
    fromConsiglio: {
      type: Boolean,
      default: false,
    },
    fromTecnici: {
      type: Boolean,
      default: false,
    },
    fromAddEnte: {
      type: Boolean,
    },
    fromReferente: {
      type: Boolean,
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    TextInput,
    SelectInput,
    ListComuni,
    Loading,
    SelectInputEvento,
  },
  setup(props, { emit }) {
    const store = useStore();

    //routing
    const router = useRouter(); //usato per redirect

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // page events
    onMounted(() => {
      if (router.currentRoute._value.name == "add-persona") {
        setCurrentPageBreadcrumbs("Aggiungi Persona", ["Lista Persone"]);
      }
    });

    // inizializzazioni
    const inputFocus = ref("");

    const cod_fisc = ref("");
    const cod_fisc_prec = ref("");
    const disabile = ref(false);
    const transgender = ref(false);
    const data_nascita = ref(new Date());
    const data_doc = ref(null);
    const straniero = ref(0);
    const comune_nascita_label = ref("");
    const comune_nascita = ref(0);
    const stato_nascita = null;
    const localita_nascita = ref("");
    const permesso_Soggiorno = ref(false);
    const data_Scadenza_Permesso_Soggiorno = ref(null);

    //italia o estero
    const nazione_residenza = ref("");
    const id_provincia_residenza = ref(0);
    const id_provincia_rilascio = ref(0);
    const id_comune_residenza = ref(0);
    const id_comune_rilascio = ref(0);
    const comune_residenza_txt = ref("");

    const comune_rilascio_txt = ref("");
    const selectedItaliaR = ref(false);
    const selectedProvinciaR = ref(false);
    const selectedProvinciaRD = ref(false);

    const lingue2disabled = ref(true);
    const lingue3disabled = ref(true);
    const lingue4disabled = ref(true);
    const id_lingue1 = ref(null);
    const id_lingue2 = ref(null);
    const id_lingue3 = ref(null);
    const id_lingue4 = ref(null);

    // lookups - store GETTERS
    const titoliStudio = computed(() =>
      store.getters.getStateFromName("titoli_studio")
    );
    const lingueParlate = computed(() =>
      store.getters.getStateFromName("lingue_parlate")
    );
    const taglie = computed(() => store.getters.getStateFromName("taglie"));
    const statiCittadinanza = computed(() =>
      store.getters.getStateFromName("stati_cittadinanza")
    );
    const statiNascita = computed(() =>
      store.getters.getStateFromName("stati_nascita")
    );
    const statiResidenza = computed(() =>
      store.getters.getStateFromName("stati_residenza")
    );
    const tipiDocIdentita = computed(() =>
      store.getters.getStateFromName("tipo_docum_identita")
    );
    const tipiDocRilascio = computed(() =>
      store.getters.getStateFromName("tipo_docum_rilascio")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const comuniIstat = computed(() =>
      store.getters.getStateFromName("comuni_istat")
    );
    const comuniNascita = computed(() =>
      store.getters.getStateFromName("comuni_nascita")
    );

    // lookups chiamate - store ACTIONS
    const keys = ref("ts|lp|t");
    if (!titoliStudio.value || !lingueParlate.value || !taglie.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const keys2 = ref("sn|sc|sr|ip");
    if (
      !statiNascita.value ||
      !statiCittadinanza.value ||
      !statiResidenza.value ||
      !province.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys2.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const keys3 = ref("tdi|tdr|ptds");
    if (!tipiDocIdentita.value || !tipiDocRilascio.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys3.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    // altre store ACTIONS
    const setComuniNascita = (string) => {
      if (string.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            nomec: string,
          },
          apiLink: globalApi.COMUNI_NASCITA_LIST,
        });
      }
    };

    const setComuni = (comuneString, id) => {
      store.dispatch("setStoreData", {
        keys: {
          attivo: true,
          nomec: comuneString,
          codp: id,
        },
        apiLink: globalApi.COMUNI_ISTAT_LIST,
      });
    };

    //store MUTATIONS
    const spliceComuniNascita = () => {
      store.commit("spliceArrayFromName", "comuni_nascita");
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    // FUNZIONI nella pagina
    const changeselectedItaliaR = (e) => {
      if (e === "IT") {
        selectedItaliaR.value = true;
        selectedProvinciaR.value = false;
      } else {
        selectedItaliaR.value = false;
        id_provincia_residenza.value = null;
        id_comune_residenza.value = 0;
      }
    };

    const changeselectedProvinciaR = (e) => {
      if (e === undefined || e === null || e === "-") {
        id_provincia_residenza.value = null;
        selectedProvinciaR.value = false;
        id_comune_residenza.value = 0;
      } else if (e != id_provincia_residenza.value) {
        selectedProvinciaR.value = true;
        id_provincia_residenza.value = parseInt(e);
        id_comune_residenza.value = 0;
      } else {
        id_provincia_residenza.value = parseInt(e);
        selectedProvinciaR.value = true;
      }
    };

    const changeselectedProvinciaRD = (e) => {
      if (e === undefined || e === null || e === "-") {
        id_provincia_rilascio.value = null;
        selectedProvinciaRD.value = false;
        id_comune_rilascio.value = 0;
      } else if (e != id_provincia_rilascio.value) {
        selectedProvinciaRD.value = true;
        id_provincia_rilascio.value = parseInt(e);
        id_comune_rilascio.value = 0;
      } else {
        selectedProvinciaRD.value = true;
      }
    };

    const changeLingua1 = (e) => {
      id_lingue1.value = e;
      if (e === null) {
        id_lingue2.value = null;
        id_lingue3.value = null;
        id_lingue4.value = null;
        lingue2disabled.value = true;
        lingue3disabled.value = true;
        lingue4disabled.value = true;
      } else {
        lingue2disabled.value = false;
      }
    };

    const changeLingua2 = (e) => {
      id_lingue2.value = e;
      if (e === null) {
        id_lingue3.value = null;
        id_lingue4.value = null;
        lingue3disabled.value = true;
        lingue4disabled.value = true;
      } else {
        lingue3disabled.value = false;
      }
    };

    const changeLingua3 = (e) => {
      id_lingue3.value = e;
      if (e === null) {
        id_lingue4.value = null;
        lingue4disabled.value = true;
      } else {
        lingue4disabled.value = false;
      }
    };

    const changeLingua4 = (e) => {
      id_lingue4.value = e;
    };

    const changeDataPermesso = () => {
      data_Scadenza_Permesso_Soggiorno.value = null;
    };

    // form validation
    const schema = yup.object().shape({
      nome: yup.string().required("Obbligatorio"),
      cognome: yup.string().required("Obbligatorio"),
      data_nascita: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("checkCodFisc"),

      cod_fisc: yup
        .string()
        .required("Obbligatorio")
        .test(
          "checkCodFisc",
          "Inserire un codice fiscale valido",
          async function (value) {
            if (value === undefined) return false;
            if (value === cod_fisc_prec.value) return true;
            if (value.length === 16) {
              if (value === cod_fisc_prec.value) return true;
              const isValidCodFisc = await checkCodFisc(
                value,
                globalApi.COD_FISC_PERSONE
              );
              cod_fisc.value = value;
              cod_fisc_prec.value = value;

              return isValidCodFisc;
            } else {
              return false;
            }
          }
        ),
      sesso: yup.string().oneOf(["M", "F"], "Obbligatorio"),
      cittadinanza: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      /* cittadinanza_sportiva: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"), */
      email: yup
        .string()
        .email("Inserire un email valida")
        .required("Obbligatorio"),
      skype: yup.string(),

      //luogo nascita
      straniero: yup
        .number("Obbligatorio")
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      stato_nascita: yup
        .number("Obbligatorio")
        .typeError("Obbligatorio")
        .when("straniero", {
          is: (straniero) => {
            return straniero === 1;
          },
          then: yup.number().required("Obbligatorio").typeError("Obbligatorio"),
          otherwise: yup.number().nullable(),
        }),
      comune_nascita: yup
        .number()
        .nullable()
        .when("straniero", {
          is: (straniero) => {
            return straniero === 0;
          },
          then: yup
            .number()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Obbligatorio"),
          otherwise: yup.number().nullable(),
        }),

      //residenza
      nazione_residenza: yup
        .string()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      prov_Residenza: yup
        .number()
        .nullable()
        .when("nazione_residenza", {
          is: (nazione_residenza) => {
            return nazione_residenza === "IT";
          },
          then: yup
            .number()
            .nullable()
            .moreThan(0, "Obbligatorio")
            .required("Obbligatorio")
            .typeError("Obbligatorio"),
        }),
      comune_residenza: yup
        .number()
        .nullable()
        .when("prov_Residenza", {
          is: (prov_Residenza) => {
            return prov_Residenza > 0;
          },
          then: yup
            .number()
            .nullable()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Riseleziona un comune dalla lista dinamica..."),
        }),
      indirizzo_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza !== null;
        },
        then: yup.string().required("Obbligatorio"),
      }),
      cap_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      localita_residenza: yup.string().when("nazione_residenza", {
        is: (nazione_residenza) => {
          return nazione_residenza !== "IT";
        },
        then: yup.string().required("Obbligatorio"),
      }),
      prov_Doc: yup
        .number()
        .nullable()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      comune_Doc: yup
        .number()
        .nullable()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),

      telefono: yup.string(),
      telefono2: yup.string(),
      fax: yup.string(),
      mobile: yup.string(),
      // documento identita
      data_doc: yup.string().nullable(),
      tipo_doc: yup.string(),
      numero_doc: yup.string(),

      rilascio_doc: yup.string(),

      note_doc: yup.string(),

      //coordinate bancarie
      banca: yup.string(),
      indirizzo_banca: yup.string(),
      iban: yup.string(),
      banca2: yup.string(),
      indirizzo_banca2: yup.string(),
      iban2: yup.string(),
      //conto corrente postale

      //altre informazioni
      taglia: yup.string(),
      peso: yup.string(),
      altezza: yup.string(),
      //cognome materno
      id_Titolo_Studio: yup.string(),
      id_lingue1: yup.string().nullable(),
      id_lingue2: yup.string().nullable(),
      id_lingue3: yup.string().nullable(),
      id_lingue4: yup.string().nullable(),
      targa1: yup.string(),
      targa2: yup.string(),

      //privacy
      privacy1: yup
        .boolean()
        .oneOf([true], "Devi accettare questo paragrafo")
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      privacy2: yup.boolean().typeError("Obbligatorio"),
      permesso_Soggiorno: yup
        .boolean()
        .nullable()
        .transform((value) => (value === "" ? false : value)),
      data_Scadenza_Permesso_Soggiorno: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value))
        .when("permesso_Soggiorno", {
          is: (permesso_Soggiorno) => {
            return permesso_Soggiorno && view_scadenza_permesso_soggiorno.value;
          },
          then: yup
            .string()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (value === "" ? null : value)),

          otherwhise: yup
            .string()
            .nullable()
            .transform((value) => (value === "" ? null : value)),
        }),
    });

    const isLoading = ref(false);
    // invio form
    const onSubmit = (values) => {
      isLoading.value = true;

      values = {
        ...values,
        // stato_nascita: 0,
        // comune_residenza: values.id_comune_residenza,
        id_tipo_docum_straniero: id_tipo_docum_straniero.value,
        data_Scadenza_Permesso_Soggiorno:
          values.permesso_soggiorno && !view_scadenza_permesso_soggiorno.value
            ? "2099-12-31"
            : values.data_Scadenza_Permesso_Soggiorno,
      };

      console.log(JSON.stringify(values, null, 2));
      // alert(JSON.stringify(values, null, 2));

      addPersona(values).then((res) => {
        isLoading.value = false;

        if (res.status == 200) {
          alertSuccess("Persona aggiunta con successo");
          if (props.fromTesseramento) {
            document.getElementById("OpenAddTesserato").click();
            emit("setupTesseramento", {
              id_pers: res.data.id,
              nome_completo: `${values.cognome}, ${
                values.nome
              } ${fromUTCtoISODateDMY(values.data_nascita)}`,
            });
          } else if (
            props.fromConsiglio ||
            props.fromTecnici ||
            props.fromSocieta ||
            props.fromAddSocieta ||
            props.fromReferente
          ) {
            document.getElementById("closeAddPersona").click();
          } else {
            router.push(
              `/persone/dettaglio-persona/anagrafica-persona/${res.data.id}`
            );
          }
        } else {
          const strMsg = ref(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      });
    };

    // form non valido
    const onInvalidSubmit = (v) => {
      let strMsg = "Compila i campi obbligatori";
      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };

    const id_tipo_docum_straniero = ref(null);
    const view_scadenza_permesso_soggiorno = ref(null);

    const cambiaPermessoDiSoggiorno = (e) => {
      if (e.id) {
        permesso_Soggiorno.value = true;
        id_tipo_docum_straniero.value = e.id;
        view_scadenza_permesso_soggiorno.value = e.scadenza;
        if (!e.scadenza) {
          data_Scadenza_Permesso_Soggiorno.value = null;
        }
      } else {
        permesso_Soggiorno.value = true;
        id_tipo_docum_straniero.value = e.id;
        view_scadenza_permesso_soggiorno.value = e.scadenza;
        data_Scadenza_Permesso_Soggiorno.value = null;
      }
    };

    const straniero_tipo_documenti = computed(() =>
      store.getters.getStateFromName("straniero_tipo_documenti")
    );

    // page bindings
    return {
      // campi e variabili pagina
      straniero_tipo_documenti,
      cambiaPermessoDiSoggiorno,
      view_scadenza_permesso_soggiorno,
      id_tipo_docum_straniero,
      inputFocus,
      schema,
      cod_fisc,
      cod_fisc_prec,
      straniero,
      comune_nascita_label,
      comune_nascita,
      stato_nascita,
      localita_nascita,
      data_nascita,
      data_doc,
      nazione_residenza,
      id_provincia_residenza,
      id_provincia_rilascio,
      id_comune_residenza,
      id_comune_rilascio,
      comune_residenza_txt,
      changeDataPermesso,
      disabile,
      transgender,
      comune_rilascio_txt,
      id_lingue1,
      id_lingue2,
      id_lingue3,
      id_lingue4,
      transgender_tipi: [
        { id: "MF_O", name: "MF operato" },
        { id: "MF_NO", name: "MF non operato" },
        { id: "FM", name: "FM" },
      ],
      sesso: [
        { id: "M", name: "M" },
        { id: "F", name: "F" },
      ],
      // { id: "R", name: "raramente..." },
      selectedItaliaR,
      selectedProvinciaR,
      selectedProvinciaRD,
      lingue2disabled,
      lingue3disabled,
      lingue4disabled,
      //liste
      statiCittadinanza,
      statiNascita,
      statiResidenza,
      taglie,
      titoliStudio,
      tipiDocIdentita,
      province,
      lingueParlate,
      tipiDocRilascio,
      comuniIstat,
      comuniNascita,
      // funzionalità
      format,
      changeLingua1,
      changeLingua2,
      changeLingua3,
      changeLingua4,
      changeselectedItaliaR,
      changeselectedProvinciaR,
      changeselectedProvinciaRD,
      spliceComuniNascita,
      setComuniNascita,
      spliceComuniIstat,
      setComuni,
      checkCodFisc,
      onSubmit,
      onInvalidSubmit,
      permesso_Soggiorno,
      data_Scadenza_Permesso_Soggiorno,

      isLoading,
      isEnabled,
    };
  },
});
</script>
