import services from "../axios/dbManag";
import store from "../store/index";

const token = store.getters.tokenAdb2c
  ? store.getters.tokenAdb2c
  : localStorage.getItem("tokenAdb2c");

export const tessereSottoscrivibili = (
  stagione,
  id_Persona,
  id_Societa_Richiesta
) => {
  return services.dbManag
    .post("/tesseramento/tessere/sottoscrivibili", {
      modalita_Tesseramento: 2,
      stagione: stagione,
      id_Persona: id_Persona,
      id_Societa_Richiesta: id_Societa_Richiesta,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addTesseratoSocieta = (tesserato) => {
  return services.dbManag
    .post("/tesseramento/societa/tesserati/add", {
      ...tesserato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewTesserato = (id) => {
  return services.dbManag
    .post("/tesseramento/societa/tesserati/view", {
      id,
    })
    .then((res) => {
      return res.data.results[0];
    })
    .catch(() => {
      return false;
    });
};
export const editTesserato = (tesserato) => {
  return services.dbManag
    .post("/tesseramento/societa/tesserati/edit", {
      ...tesserato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteTesserato = (id, cod_tessera, anno) => {
  return services.dbManag
    .post("/tesseramento/societa/tesserati/del", {
      id,
      cod_tessera,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const approvaTesseramentoSoc = (
  id_Tesseramento_Da_Approvare,
  id_Societa,
  id_Persona,
  id_Disciplina
) => {
  return services.dbManag
    .post("/economato/movimenti/societa/affiliazioni/approva", {
      id_Tesseramento_Da_Approvare,
      id_Societa,
      id_Persona,
      id_Disciplina,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const stampaTesseraSingola = (
  id_tesseramento,
  id_societa,
  id_persona,
  anno,
  stampa_tutte,
  stampa_da_tesserati_societa
) => {
  return services.downloadFile
    .get("/tesseramento/societa/tessera/stampa/singola", {
      params: {
        id_tesseramento,
        id_societa,
        id_persona,
        anno,
        stampa_tutte,
        stampa_da_tesserati_societa,
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${id_tesseramento}.pdf`);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const stampaTessereTutte = (
  id_tesseramento,
  id_societa,
  id_persona,
  anno,
  stampa_tutte,
  stampa_da_tesserati_societa
) => {
  return services.downloadFile
    .get("/tesseramento/societa/tessera/stampa", {
      params: {
        id_tesseramento,
        id_societa,
        id_persona,
        anno,
        stampa_tutte,
        stampa_da_tesserati_societa,
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${id_societa}.pdf`);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const stampaTessereTutteArray = (guid) => {
  const url = `${services.baseUrl}/tesseramento/societa/tessera/da-array/stampa?guid=${guid}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `stampa_tessere_FIT.pdf`);
  document.body.appendChild(link);
  document.body.removeChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};
export const stampaTessereTutteBW = (guid) => {
  const url = `${services.baseUrl}/tesseramento/societa/tessera/stampa/bw?guid=${guid}&request=${token}`;
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = url;
  link.setAttribute("download", `stampa_tessere_FIT.pdf`);
  document.body.appendChild(link);
  document.body.removeChild(link);
  setTimeout(() => {
    link.click();
    document.body.removeChild(link);
  }, 500);
};
export const stampaTesseraGold = (id_persona) => {
  return services.downloadFile
    .get("/tesseramento/societa/tessera/stampa/gold", {
      params: {
        id_persona,
      },
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${id_persona}_gold.pdf`);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const preparaStampa = (arr_id_tesseramento, anno) => {
  return services.dbManag
    .post("/tesseramento/societa/tessera/array/add", {
      arr_id_tesseramento,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
