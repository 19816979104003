<template>
  <div>
    <TableDocAffiliazione
      @getDocumentiSocieta="getDocumentiSocieta"
      @resetFilters="resetFilters"
      :societa="societa"
      :affiliazioneSecondaria="affiliazioneSecondaria"
      :objControlloDownloadDocumentoAffiliazione="
        objControlloDownloadDocumentoAffiliazione
      "
    />
    <AddDocAffiliazione
      :societa="societa"
      @documentAdded="getDocumentiSocieta"
      :affiliazioneSecondaria="affiliazioneSecondaria"
    />
  </div>
</template>

<script>
import { defineComponent, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import TableDocAffiliazione from "@/components/components-fit/enti-aggregati/affiliazioni/TableDocAffiliazione.vue";
import AddDocAffiliazione from "@/components/components-fit/enti-aggregati/affiliazioni/AddDocAffiliazione.vue";

export default defineComponent({
  name: "documenti-societa",
  components: {
    TableDocAffiliazione,
    AddDocAffiliazione,
  },
  props: {
    societa: {
      type: Number,
      required: true,
    },
    affiliazioneSecondaria: {
      type: Boolean,
    },
    objControlloDownloadDocumentoAffiliazione: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipDocSoc);
    const fetchRows = computed(() => store.getters.fetchRowsDocSoc);
    const sortColumn = computed(() => store.getters.sortColumnDocSoc);
    const sortOrder = computed(() => store.getters.sortOrderDocSoc);

    const getDocumentiSocieta = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          anno: stagione.value,
          data_da: null,
          data_a: null,
          id_tipo: null,
          acl: null,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.AFFILIAZIONI_ENTI_AGGREGATI_DOCUMENTI_LIST,
        itemName: "documenti_societa_list",
      });
    };
    getDocumentiSocieta();

    const resetFilters = () => {
      store.commit("resetFiltersDocSoc");
      getDocumentiSocieta();
    };
    getDocumentiSocieta();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadeddocumenti_societa_list")
    );

    const searchDocSocList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipDocSoc");
      getDocumentiSocieta();
    };

    return {
      getDocumentiSocieta,
      resetFilters,
      searchDocSocList,
    };
  },
});
</script>
