import services from "../axios/dbManag";

export const editReferente = (persona) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/referente-responsabile/edit", {
      ...persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const addReferente = (
  id_persona,
  id_qualifica,
  dataInizio,
  dataFine,
  anno,
  id_societa
) => {
  return services.dbManag
    .post("/affiliazioni/societa/referente/add", {
      id_persona,
      id_qualifica,
      dataInizio,
      dataFine,
      anno,
      id_societa,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const listConsiglio = (id_societa, anno) => {
  return services.dbManag
    .post("/affiliazioni/societa/referenti/list", {
      anno,
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const listReferenteEnti = (id_societa, anno) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/referente-responsabile/list", {
      anno,
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editConsiglioEnti = (persona) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/consiglio-direttivo/edit ", {
      ...persona,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
