import services from "../axios/dbManag";

export const approvaMovAffiliazione = (id_Movimento) => {
  return services.dbManag
    .post("/economato/movimenti/affiliazioni/approva", {
      id_Movimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const ratificaMovAffiliazione = (id_societa, stagione) => {
  return services.dbManag
    .post("/affiliazioni/societa/ratifica/affiliazione", {
      id_societa,
      stagione,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

// export const deleteMovAffiliazione = (id_Movimento) => {
//   return services.dbManag
//     .post("/economato/movimenti/affiliazioni/del", {
//       id_Movimento,
//     })
//     .then((res) => {
//       return res;
//     })
//     .catch((e) => {
//       return e.response;
//     });
// };
