<template>
  <div>
    <div class="row mt-5">
      <div class="col-sm-6">
        <div>
          <button
            @click="
              $emit('viewAddImpiantoLista', false);
              $emit('viewAssegnazioneDetail', false);
            "
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista impianti
          </button>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          impianti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        impianti_assegna_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="impianti_assegna_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsImpAss"
        @current-change="setCurrentPageImpAss"
        :fromAffiliazione="true"
      >
        <template v-slot:cell-denominazione="{ row: data }">
          <!-- <router-link
              :to="`/impianti/dettaglio-impianti/anagrafica-impianti/${data.id}`"
              >}</router-link> -->
          <div class="tab-long">
            {{ data.denominazione }}
          </div>
        </template>
        <template v-slot:cell-localita="{ row: data }">
          <div class="tab-long-xl">
            {{ data.localita }}
          </div>
        </template>
        <template v-slot:cell-db_provenienza="{ row: data }">
          {{ data.db_provenienza }}
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div
            v-if="!readOnly"
            class="btn-group"
            @click="
              $emit('AssCampi', data.id);
              $emit('viewAssegnazioneDetail', true);
            "
          >
            <button
              class="btn btn-sm dropdown"
              type="button"
              aria-expanded="false"
            >
              <i class="bi bi-clipboard-check text-dark fs-4"></i
              ><span class="fs-7">Assegna</span>
            </button>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableAssegnaImpianto",
  components: {
    Datatable,
  },
  emits: [
    "getImpAssList",
    "resetFilters",
    "viewAddImpiantoLista",
    "viewAssegnazioneDetail",
    "AssCampi",
  ],
  props: {
    societa: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const route = useRoute(); // prende id
    const id_societa = ref(route.params.id);
    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const store = useStore();

    const setFetchRowsImpAss = (e) => {
      store.commit("setFetchRowsImpAss", e);
      emit("getImpAssList");
    };
    const setCurrentPageImpAss = (page) => {
      store.commit("setCurrentPageImpAss", page);
      emit("getImpAssList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnImpAss", columnName);
      store.commit("setSortOrderImpAss", order);
      emit("getImpAssList");
    };

    const tableHeader = ref([
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Località",
        key: "localita",
        sortable: false,
      },
      {
        name: "Provenienza Dato",
        key: "db_provenienza",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    return {
      id_societa,
      stagioneSelected,
      impianti_assegna_list: computed(() =>
        store.getters.getStateFromName("resultsimpianti_assegna_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimpianti_assegna_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordimpianti_assegna_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusimpianti_assegna_list")
      ),

      currentPage: computed(() => store.getters.currentPageImpAss),
      rowsToSkip: computed(() => store.getters.rowsToSkipImpAss),
      fetchRows: computed(() => store.getters.fetchRowsImpAss),
      sortColumn: computed(() => store.getters.sortColumnImpAss),
      sortOrder: computed(() => store.getters.sortOrderImpAss),
      setFetchRowsImpAss,
      setCurrentPageImpAss,
      setSortOrderColumn,
      tableHeader,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      isEnabled,
    };
  },
};
</script>

<style scoped>
ul.dropdown-menu.show {
  position: fixed !important;
}
</style>
