<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div v-if="!addImpiantoLista">
      <div class="alert alert-warning" role="alert" v-if="proponiDati">
        <b
          >Attenzione: Sono presentati i dati relativi agli impianti dell'anno
          {{ stagioneSelected - 1 }}
        </b>
        <br />
        Cliccando su "conferma impianti" i campi verranno riassegnati per la
        stagione selezionata
      </div>
      <div class="row mt-5" v-else>
        <div class="col-6">
          <div v-if="!readOnly">
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start"
              @click="
                addImpiantoLista = true;
                showAssegnazioneDetail = false;
                setFetchRowsImpAss(5);
              "
            >
              <i class="bi bi-plus fs-6 text-gray-700"></i>assegna impianto
              aggregato
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="text-end mt-4" style="margin-right: 19px">
            Impianti assegnati:
            <b>{{ listaImpianti.length ? listaImpianti.length : "0" }}</b>
          </div>
        </div>
      </div>
      <br />
      <div v-if="loadedImpianti && listaImpianti.length">
        <div class="table-responsive card shadow">
          <table class="table table-striped gy-3 gs-7 align-middle">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
              >
                <th scope="col">Denominazione</th>
                <th scope="col">Indirizzo</th>
                <th scope="col">Comune</th>
                <th scope="col">Campi assocati</th>
                <th scope="col" class="w-5" v-if="!proponiDati"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in listaImpianti" :key="i">
                <tr :class="item.principale ? 'text-success' : ''">
                  <td :class="item.principale ? 'text-success' : ''">
                    <i
                      v-if="item.principale"
                      class="bi bi-pin-angle text-success fs-4"
                    >
                    </i>
                    {{ item.denominazione }}
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    {{ item.indirizzo }} {{ item.num_civico }}
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    {{ item.provincia }} ({{ item.siglaProvincia }})
                  </td>
                  <td :class="item.principale ? 'text-success' : ''">
                    <template v-for="(campoItem, j) in item.campi" :key="j">
                      {{ campoItem.nome_campo }}
                      <span v-if="campoItem.nome_campo"> - </span>
                      {{ campoItem.disciplina }}
                      <br />
                    </template>
                  </td>
                  <td class="w-5" v-if="!proponiDati">
                    <div class="btn-group">
                      <button
                        class="btn btn-sm dropdown"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          :class="
                            item.principale
                              ? 'bi bi-three-dots-vertical text-success fs-4'
                              : 'bi bi-three-dots-vertical text-dark fs-4'
                          "
                        ></i>
                      </button>
                      <ul class="dropdown-menu" style="min-width: 18rem">
                        <a
                          href="#"
                          v-if="!readOnly && !item.principale"
                          @click="
                            setImpPrincipale(
                              item.id_impianto,
                              stagioneSelected,
                              id_societa
                            )
                          "
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-pin-angle text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Rendi principale
                                </span>
                              </div>
                            </div>
                          </li></a
                        >
                        <a
                          href="#"
                          v-if="!readOnly"
                          @click="AssCampi(item.id_impianto)"
                        >
                          <li role="presentation" class="navi-item">
                            <div class="b-dropdown-text text-start py-2 px-4">
                              <div class="navi-link">
                                <i class="bi bi-pen text-dark fs-5"></i>
                                <span class="text-dark ps-1 fs-7">
                                  Modifica assegnazione campi</span
                                >
                              </div>
                            </div>
                          </li></a
                        >
                      </ul>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="pt-5 text-end"
          v-if="!readOnly && proponiDati && !addImpiantoLista"
        >
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            @click="
              addImpiantoLista = true;
              setFetchRowsImpAss(5);
            "
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>assegna manualmente
          </button>
          <button
            type="button"
            class="badge bg-secondary rounded blueFit fs-5 ms-5"
            @click="riassegnaImpianti()"
          >
            Conferma impianti
          </button>
        </div>
      </div>
      <div
        v-else-if="loadedImpianti && !listaImpianti.length"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun impianto.
        </span>
      </div>
      <div v-else>
        <div class="mx-auto my-auto text-center">
          <div class="spinner-border text-gray-600" role="status"></div>
          <span class="text-gray-600 ps-3 fs-2">Loading</span>
        </div>
      </div>
      <br />
    </div>
    <div v-if="addImpiantoLista">
      <AssegnaImpiantoAffiliazione
        @viewAddImpiantoLista="viewAddImpiantoLista"
        @viewAssegnazioneDetail="viewAssegnazioneDetail"
        @AssCampi="AssCampi"
      />
    </div>
    <div v-if="showAssegnazioneDetail">
      <AssegnazioneCampiAffiliazione
        :impianto="selectedImpianto"
        :ente="societa"
        :ente_cod_affiliazione="societa_cod_affiliazione"
        :isLoadingDetail="isLoadingDetail"
        @viewAssegnazioneDetail="viewAssegnazioneDetail"
        @loadImpiantiSocieta="loadImpiantiSocieta"
        @viewAddImpiantoLista="viewAddImpiantoLista"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import {
  viewEditImpiantoEnteAggregato,
  setPrincipaleEnteAggregato,
  confermaAssegnazioneEnteAggregato,
} from "@/requests/addImpianto";
import services from "../../../../axios/dbManag";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import AssegnaImpiantoAffiliazione from "./AssegnaImpiantoAffiliazione.vue";
import AssegnazioneCampiAffiliazione from "./AssegnazioneCampiAffiliazione.vue";

export default defineComponent({
  name: "impianti-societa",
  components: {
    Loading,
    AssegnaImpiantoAffiliazione,
    AssegnazioneCampiAffiliazione,
  },
  props: ["societa", "campiLocked", "societa_cod_affiliazione"],
  setup(props) {
    const loadedImpianti = ref(false);
    const listaImpianti = ref([]);
    const store = useStore();
    const isLoading = ref(false);

    const stagioneSelected = computed(() => store.getters.stagioneSelected);
    const id_societa = ref(props.societa);
    const proponiDati = ref(false);

    const loadImpiantiSocieta = () => {
      loadedImpianti.value = false;
      proponiDati.value = false;
      services.dbManag
        .post(`/affiliazioni/enti-aggregati/impianti/list`, {
          anno: stagioneSelected.value,
          id_societa: props.societa.id,
        })
        .then((res) => {
          listaImpianti.value.splice(0, listaImpianti.value.length);
          res.data.results.forEach((element) => {
            listaImpianti.value.push(element);
          });
          loadedImpianti.value = true;
          if (listaImpianti.value.length > 0) {
            proponiDati.value = res.data.proponiDati;
          }
        });
    };
    loadImpiantiSocieta();

    const refreshList = () => {
      loadImpiantiSocieta();
    };

    const isLoadingDetail = ref(false);

    const selectedImpianto = ref({});
    const AssCampi = (id_Impianto) => {
      selectedImpianto.value = {};
      isLoadingDetail.value = true;
      viewEditImpiantoEnteAggregato(
        id_Impianto,
        stagioneSelected.value,
        props.societa
      ).then((res) => {
        if (res.length > 0) {
          selectedImpianto.value = res[0];
          showAssegnazioneDetail.value = true;
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_assegna_campo"
          );
        }
        isLoadingDetail.value = false;
      });
    };

    const setImpPrincipale = async (id_Impianto, anno, id_Societa) => {
      isLoading.value = true;
      selectedImpianto.value = {};
      await setPrincipaleEnteAggregato(id_Impianto, anno, id_Societa).then(
        (res) => {
          isLoading.value = false;
          selectedImpianto.value = res[0];
          if (res.statusCode === 200) {
            refreshList();
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        }
      );
    };

    watch(stagioneSelected, () => {
      loadImpiantiSocieta();
    });

    const riassegnaImpianti = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo gli impianti verranno assegnati alla società",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          confermaAssegnazioneEnteAggregato(
            props.societa.id,
            stagioneSelected.value
          ).then((res) => {
            if (res.data.statusCode == 200) {
              alertSuccess("Campi assegnati correttamente");
              loadImpiantiSocieta();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const addImpiantoLista = ref(false);
    const viewAddImpiantoLista = (event) => {
      addImpiantoLista.value = event;
    };

    const showAssegnazioneDetail = ref(false);
    const viewAssegnazioneDetail = (event) => {
      showAssegnazioneDetail.value = event;
    };

    const setFetchRowsImpAss = (e) => {
      store.commit("setFetchRowsImpAss", e);
    };

    return {
      listaImpianti,
      loadedImpianti,
      AssCampi,
      selectedImpianto,
      setImpPrincipale,
      id_societa,
      stagioneSelected,
      refreshList,
      isLoading,
      isLoadingDetail,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      proponiDati,
      riassegnaImpianti,
      addImpiantoLista,
      viewAddImpiantoLista,
      setFetchRowsImpAss,
      viewAssegnazioneDetail,
      showAssegnazioneDetail,
      loadImpiantiSocieta,
    };
  },
});
</script>

<style scoped>
ul.dropdown-menu.show {
  position: fixed !important;
}
</style>
