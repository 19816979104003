import services from "../axios/dbManag";

export const addImpianto = (impianto) => {
  return services.dbManag
    .post("/anagrafiche/impianti/add", {
      ...impianto,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const viewImpianto = (id) => {
  return services.dbManag
    .post("/anagrafiche/impianti/view", {
      id,
    })
    .then((res) => {
      return res.data.results;
    })
    .catch(() => {
      return false;
    });
};

export const viewEditImpianto = (id_Impianto, anno, id_Societa) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/view", {
      id_Impianto,
      anno,
      id_Societa,
    })
    .then((res) => {
      return res.data.results;
    })
    .catch((e) => {
      return e.response;
    });
};

export const assegnazioneCampi = (impianto) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/add", {
      ...impianto,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

/* export const editAssegnazioneCampi = (impianto) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/add", {
      ...impianto,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
}; */

export const setPrincipale = (id_Impianto, anno, id_Societa) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/set-principale", {
      id_Impianto,
      anno,
      id_Societa,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const viewEditImpiantoEnteAggregato = (
  id_Impianto,
  anno,
  id_Societa
) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/impianti/view", {
      id_Impianto,
      anno,
      id_Societa,
    })
    .then((res) => {
      return res.data.results;
    })
    .catch((e) => {
      return e.response;
    });
};

export const assegnazioneCampiEnteAggregato = (impianto) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/impianti/add", {
      ...impianto,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const setPrincipaleEnteAggregato = (id_Impianto, anno, id_Societa) => {
  return services.dbManag
    .post("/affiliazioni/enti-aggregati/impianti/set-principale", {
      id_Impianto,
      anno,
      id_Societa,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const delImpianto = (id) => {
  return services.dbManag
    .post("/anagrafiche/impianti/del", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const numberImpianti = (societa) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/list", {
      ...societa,
    })
    .then((res) => {
      return res;
    })
    .catch(() => false);
};

export const confermaAssegnazione = (id_societa, anno) => {
  return services.dbManag
    .post("/affiliazioni/societa/impianti/associata/tutti/anno/precedente", {
      id_societa,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
